<template>
  <div class="header">
    <div>
      <img class="logo" :src="loadUrl.logo"
        @click="router.push('/'); store.state.titleIndex = 0;" />
    </div>
    <div class="title">
      <div v-for="(item, index) in titleList" :key="index"
        :class="((selectIndex == index ? 'active' : '') + (hoverTitleIndex == index ? ' hoverActive' : '')) + (item.hasChild ? ' has-child' : '')"
        :style="{ paddingRight: item.hasChild ? '22rem' : '10rem' }" @click="selectModel(index, item.path)"
        @mousemove.stop="handleMouseMove(item.key || '', index)" @mouseleave="handleMouseLeave()">
        {{ item.name }}
      </div>
    </div>
    <div class="page-list" :class="isBlankBg ? 'blank-bg' : ''" v-if="!hideTitle && pageTitle.length">
      <div class="list-content">
        <span v-for="(item, index) in pageTitle" @click="changeTitle(item)" :key="index">{{ item }}</span>
      </div>
    </div>
    <div class="title-list" :class="isBlankBg ? 'blank-bg' : ''" v-show="hoverKey" @mousemove="mouseMoveTitle"
      @mouseleave="mouseLeaveTitle()">
      <div :class="hoverKey">
        <div v-for="(item, index) in childPages[hoverKey]" :key="index"
          @click="changeUrlEvent(item, item.index, item.listIndex)">{{
            item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { pathTable, pageRoute } from '@/config'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()

const props = defineProps({
  changePath: {
    default: null,
    type: Function
  },
  hideTitle: {
    default: false,
    type: Boolean
  }
})
const pageTitle: any = ref([])
const selectIndex = ref(0)
const hoverKey = ref('')
const isBlankBg = ref(false)
let timer: any = null;
const childPages: any = ref({
  service: [
    {
      name: '数智产品',
      url: '/product_service',
      title: ['产品服务', '数智产品'],
      index: 1
    },
    {
      name: '渠道拓展',
      url: '/channel_expansion',
      title: ['产品服务', '渠道拓展'],
      index: 1
    }
  ],
  programme: [
    {
      name: '文娱解决方案',
      url: '/solution',
      title: ['解决方案', '文娱解决方案'],
      listIndex: '0',
      index: 2
    },
    {
      name: '工业解决方案',
      url: '/solution',
      title: ['解决方案', '工业解决方案'],
      listIndex: '1',
      index: 2
    },
    {
      name: '教育解决方案',
      url: '/solution',
      title: ['解决方案', '教育解决方案'],
      listIndex: '2',
      index: 2
    },
    {
      name: '医疗解决方案',
      url: '/solution',
      title: ['解决方案', '医疗解决方案'],
      listIndex: '3',
      index: 2
    },
    {
      name: '军警部队解决方案',
      url: '/solution',
      title: ['解决方案', '军警部队解决方案'],
      listIndex: '4',
      index: 2
    }
  ],
  about_as: [
    {
      name: '公司介绍',
      url: '/about_us_view',
      title: ['关于我们', '公司介绍'],
      index: 5
    },
    {
      name: '新闻动态',
      url: '/about_us',
      title: ['关于我们', '新闻动态'],
      index: 5
    },
    {
      name: '加入我们',
      url: '/about_detail',
      title: ['关于我们', '加入我们'],
      index: 5
    }
  ]
})
const titleList = [
  {
    name: '首页',
    path: '/'
  },
  {
    name: '产品服务',
    path: '',
    hasChild: true,
    key: 'service',

  },
  {
    name: '解决方案',
    path: '',
    hasChild: true,
    key: 'programme'
  },
  {
    name: '成功案例',
    path: '/success_cases'
  },
  {
    name: '合作生态',
    path: '/collaboratives'
  },
  {
    name: '关于我们',
    path: '',
    hasChild: true,
    key: 'about_as'
  }
]
const hoverTitleIndex = ref(-1)

const changeUrlEvent = (data: any, index: number, listIndex: string) => {
  store.state.pageTitle = [...data.title]
  store.state.titleIndex = index;
  if (listIndex) {
    router.push(`${data.url}?type=${listIndex}&pathName=${data.title[0]},${data.title[1]}`)
    store.state.programmeIndex = listIndex
  } else {
    router.push(`${data.url}?pathName=${data.title[0]},${data.title[1]}`);
  }
}

const mouseMoveTitle = () => {
  if (timer) {
    clearTimeout(timer)
    timer = null;
  }
}

const changeTitle = (title: any) => {
  if (store.state.pageTitle[0] == '数智产品') {
    if (title != '数智产品') {
      const activeIndex = store.state.pageTitle.findIndex((e: any) => e == title)
      if (activeIndex == 1) {
        window.localStorage.setItem('product-category', title)
      } else if (activeIndex == 2) {
        window.localStorage.setItem('product-category', store.state.pageTitle[1])
        window.localStorage.setItem('brand', title)
      }
    }

    router.push(pageRoute['数智产品'])
    store.state.pageTitle = ['产品服务', '数智产品']
    return
  }

  if (title.includes('解决方案')) {
    if (title == '解决方案' && store.state.pageTitle.length <= 2) return
    router.push(pageRoute[title])
    if (title == '解决方案') {
      store.state.pageTitle = ['解决方案', '文娱解决方案']
    } else {
      store.state.pageTitle = ['解决方案', title]
    }
    return
  }

  if (['关于我们', '加入我们', '新闻动态'].includes(title)) {
    router.push(pageRoute[title])
    if (title != '关于我们') {
      store.state.pageTitle = ['关于我们', title]
    } else {
      store.state.pageTitle = ['关于我们', '公司介绍']
    }
    return
  }

  if (pageRoute[title]) {
    router.push(pageRoute[title])
    store.state.pageTitle = []
  }
}

const mouseLeaveTitle = () => {
  clearTimeout(timer)
  timer = setTimeout(() => {
    hoverKey.value = ''
    hoverTitleIndex.value = -1;
  }, 500)
}

const handleMouseMove = (key: string, index: number) => {
  if (hoverKey.value != key) {
    hoverKey.value = key || ''
    clearTimeout(timer)
    timer = null;
  }
  hoverTitleIndex.value = index;
}

const handleMouseLeave = () => {
  clearTimeout(timer)
  timer = setTimeout(() => {
    hoverKey.value = ''
    hoverTitleIndex.value = -1;
  }, 500)
}

const selectModel = (index: number, path: string) => {
  if (path) {
    store.state.titleIndex = index;
    props.changePath(path);
    router.push(path)
    store.state.pageTitle = []
  }
}

onMounted(() => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  store.state.titleIndex = pathTable[path.name] || 0;
  if (path.query?.pathName) {
    const routeList = path.query?.pathName.split(',')
    store.state.pageTitle = [...routeList]
  }

  onMounted(async () => {
    const logo: any = await asynchronousRetrieval('nfznxr-images/logo.png');
    loadUrl.value = { logo }
  })
})

watch(store.state, (newState) => {
  if (newState.titleIndex != selectIndex.value) {
    selectIndex.value = newState.titleIndex
  }
  pageTitle.value = newState.pageTitle
  setTimeout(() => {
    const hasBanner = document.querySelector('.banner')
    isBlankBg.value = !hasBanner
    if (store.state.titleIndex == 0) {
      isBlankBg.value = false
    }
  })
})
</script>
<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 60rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16rem;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  z-index: 1;
  background-color: #fff;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 684rem;
    margin-left: 17vw;

    &>div {
      padding: 19rem 22rem 19rem 10rem;
      position: relative;
      cursor: pointer;
      height: 60rem;
      box-sizing: border-box;
    }

    .hoverActive.has-child {
      color: #D81417;

      &::before {
        content: '';
        width: 12rem;
        height: 12rem;
        position: absolute;
        top: 50%;
        margin-top: -6rem;
        right: 0;
        background: url(/public/images/top-red-fine.png);
        background-size: 100% 100%;
        transform: rotate(180deg);
      }
    }

    .active {
      font-weight: 600;
      color: #0A0A0A;
      font-family: PingFangSC-Medium;
    }

    .active.has-child {
      &::before {
        content: '';
        width: 12rem;
        height: 12rem;
        position: absolute;
        top: 50%;
        margin-top: -6rem;
        right: 0;
        background: url(/public/images/top-red-fine.png);
        background-size: 100% 100%;
      }
    }

    .active::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #D81417;
    }
  }

  .logo {
    width: 159rem;
    cursor: pointer;
  }

  .page-list,
  .title-list {
    position: relative;
    position: absolute;
    left: 0;
    height: 40rem;
    bottom: calc(-40rem - 1px);
    background-color: rgba(247, 247, 247, 0.20);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14rem;
    color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3rem);

    .list-content {
      width: calc(843rem + 17vw);
      text-align: left;
      display: flex;
      justify-content: left;
      align-items: center;

      &>span {
        position: relative;
        padding-right: 34rem;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          top: 5rem;
          right: 13rem;
          width: 8rem;
          height: 11rem;
          background: url(/public/images/right-icon.png);
          background-size: 100% 100%;
        }
      }

      img {
        margin: 0 13rem;
        width: 8rem;
        height: 11rem;
      }
    }

    .list-content>span:last-child {
      color: #D81417;
      font-weight: 600;

      &::after {
        content: none;
      }
    }

    .list-space {
      width: 684rem;
      margin-left: 18vw;
    }
  }

  .blank-bg {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3rem);
  }

  .title-list {
    height: 70rem;
    bottom: calc(-70rem - 1px);
    font-weight: 400;
    font-size: 16rem;
    color: rgba(255, 255, 255, 0.8);

    &>div {
      position: absolute;
      left: 0;
    }

    .service {
      left: 50%;
      margin-left: -100rem;
    }

    .programme {
      left: 50%;
      margin-left: -360rem;
    }

    .about_as {
      left: 50%;
      margin-left: 300rem;
    }

    &>div>div {
      padding: 0 40rem;
      border-right: 1px solid #DCDCDC;
      height: 20rem;
      cursor: pointer;
      font-weight: 400;
      font-family: PingFangSC-Regular;
      box-sizing: border-box;
      display: inline-block;

      &:hover {
        color: #D81417;
        font-weight: 600;
        font-family: PingFangSC-Medium;
      }
    }

    &>div>div:last-child {
      border-right: none;
    }
  }
}
</style>