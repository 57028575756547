<template>
  <img :src="loadUrl.view_more" />
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

onMounted(async() => {
  const view_more: any = await asynchronousRetrieval('nfznxr-images/icon/view-more.png');
  loadUrl.value = { view_more }
})
</script>
<style scoped lang="less">
img {
  width: 30rem;
  height: 30rem;
}
</style>