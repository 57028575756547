<template>
  <header-view :change-path="changePath" :hideTitle="hideTitle"></header-view>
  <div class="router-wrap">
    <router-view />
    <bottom-navigation></bottom-navigation>
  </div>
  <contact-us></contact-us>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderView from '@/components/HeaderView.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';
import { pathTable } from '@/config'
import ContactUs from '@/components/ContactUs.vue'

const router = useRouter()
const store = useStore()
const hideTitle = ref(false)


const changePath = (path: string) => {
  console.log(path)
}

// 路由拦截，这里主要根据路由确定一些组件的显隐性
router.beforeEach((to, from, next) => {
  hideTitle.value = Boolean((to.meta && to.meta.hideTitle));
  const pathInfo = JSON.stringify({ query: to.query || {} })
  window.sessionStorage.setItem('path', pathInfo)
  const pathName = to.name || ''
  store.state.titleIndex = pathTable[pathName]
  store.state.isBlankBg = pathName
  next();
});

onMounted(() => {
  setRem()
  window.addEventListener('resize', () => {
    setRem()
  }, false)
  // 判断是不是移动端
})

const setRem = () => {
  let rootDom: any = document.querySelector('html');
  let rootWidth = document.documentElement.clientWidth || document.body.clientWidth;

  var userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.match(
      /(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone|windows mobile|windows ce|ucweb|rv:1.2.3.4|midp)/i
    )
  ) {
    rootDom.style.fontSize = rootWidth / 1920 + 'px';
    store.state.userAgent = 'mobile'
  } else {
    rootDom.style.fontSize = Math.min(Math.max(rootWidth / 1920, 0.8), 1.5) + 'px';
    store.state.userAgent = 'pc'
  }

}
</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  color: #333333;
}

body {
  font-size: 14rem;
  font-family: PingFangSC-Regular;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.left-radius {
  border-radius: 8rem 0 0 8rem;
}

.right-radius {
  border-radius: 0 8rem 8rem 0;
}

.blods {
  color: #D71819 !important;
  font-weight: 500;
  font-family: PingFangSC-Medium;
}

.form-content {
  .el-form-item__label {
    font-weight: 500;
    font-size: 18rem;
    color: #FFFFFF;
  }

  .el-form-item {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 18rem;
  }

  .el-input__wrapper {
    width: 333rem;
    height: 42rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5rem;
    box-shadow: none !important;
    margin-top: 10rem;

    input {
      border: none !important;
      font-weight: 500;
      font-size: 16rem;
      color: #FFFFFF;

      &::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .el-textarea__inner {
    margin-top: 11rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5rem;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 16rem;
    color: #FFFFFF;
    width: 333rem;
    height: 90rem;

    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
  }

  .el-form-item__error {
    font-size: 12rem;
    color: #e54949;
  }

  .btn-primary {
    width: 307rem;
    height: 50rem;
    line-height: 50rem;
    background: #D81417;
    border-radius: 6rem;
    backdrop-filter: blur(4rem);
    cursor: pointer;
    font-weight: 400;
    font-size: 20rem;
    color: #FFFFFF;
    font-family: PingFangSC-Regular;
  }
}

.contact-model {
  .form-content {

    .el-input__wrapper,
    .el-textarea__inner {
      width: 458rem;
    }
  }

  .btn-primary {
    width: 458rem;
    margin-top: 10rem;
  }

  .el-select__wrapper {
    width: 458rem;
    height: 44rem;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: none !important;
    margin-top: 11rem;

    span {
      border: none !important;
      font-weight: 500;
      font-size: 16rem;
      color: #FFFFFF;
    }

    .is-transparent span {
      color: rgba(255, 255, 255, 0.2);
    }
  }
}

.title-font-style {
  font-weight: 600;
  font-size: 60rem;
  color: #333333;
  font-family: PingFangSC-Medium;
}

.router-wrap {
  position: relative;
  padding-bottom: 276rem;
  font-family: PingFangSC-Regular;
  box-sizing: border-box;
  min-height: calc(100vh - 60rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &>div {
    width: 100%;
  }
}

.banner {
  width: 100vw;
  height: 745rem;
  position: relative;
  top: -60rem;
}

/* 分页器 */
.pagination .el-pagination {
  .el-pager>li {
    width: 32rem;
    min-width: 32rem;
    height: 32rem;
    border: 1px solid #D9D9D9;
    background-color: transparent !important;
    border-radius: 4rem;
    font-size: 14rem !important;

    &.is-active {
      border: 1px solid #D81417;
      background-color: transparent !important;
      color: #D81417 !important;
    }
  }

  .btn-prev,
  .btn-next {
    width: 32rem;
    min-width: 32rem;
    height: 32rem;
    border: 1px solid #D9D9D9;
    background-color: transparent !important;
    border-radius: 4rem;
    color: #000000 !important;

    .el-icon {
      font-size: 14rem !important;
    }
  }
}

.news-detail img {
  max-width: calc(843rem + 17vw);
  height: auto;
}

.news-detail video {
  max-width: calc(843rem + 17vw);
  height: auto;
}
</style>
