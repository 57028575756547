<template>
  <div class="education">
    <div class="title-font-style">教育解决方案</div>
    <div class="illustrate">
      <div>针对教育行业课程创建教学场景和虚拟仿真课件，实现教学内容的场景化和教学质量的闭环管理，突出互动性和趣味性，达到高效、有趣、安全的教学效果。</div>
    </div>
    <div class="subtitle">行业痛点</div>
    <div>
      <div class="disadvantage">数实结合的沉浸式教学体验可以实现对传统教学方式的变革，为教育行业带来全新的体验和机会。</div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <div>
          <img :src="loadUrl.neirong" />
          <div class="title">内容优势</div>
          <div>XR可视化培训相比传统方式，更为直观易学，有效缩短员工培训时间，提高培训效率。</div>
        </div>
        <div>
          <img :src="loadUrl.jishu" />
          <div class="title">技术优势</div>
          <div>以互动性+趣味性打造XR虚拟仿真教学平台，实现课程“教-学-练-考”的闭环课程体系。</div>
        </div>
        <div>
          <img :src="loadUrl.fuwu" />
          <div class="title">服务优势</div>
          <div>提供内容+设备+软件定制化安装服务，7x24全天候技术服务支持。</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <div>
          <img :src="loadUrl.yycj_img5" class="left-radius" />
          <div>
            <div class="title">
              <img :src="loadUrl.yycj_icon5" />
              <span>中小学教育</span>
            </div>
            <div class="content">打造虚实结合的沉浸式教学体验，提供更加直观和身临其境的学习环境，提升教学质量。</div>
          </div>
        </div>
        <div class="jiaoyu">
          <div>
            <div class="title">
              <img :src="loadUrl.yycj_icon6" class="right-radius" />
              <span>职业教育培训</span>
            </div>
            <div class="content">通过VR/AR技术进入高度逼真的三维虚拟世界里，进行虚拟实训、虚拟学习等，在虚拟环境中接受到更加系统化、全面化的培训。</div>
          </div>
          <img :src="loadUrl.yycj_img6" />
        </div>
        <div>
          <img :src="loadUrl.yycj_img7" class="left-radius" />
          <div>
            <div class="title">
              <img :src="loadUrl.yycj_icon7" />
              <span>高等院校实验室</span>
            </div>
            <div class="content">建设元宇宙虚拟实验室，以大数据、人工智能等技术为支撑，与教育教学环境深度融合创新。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div
          @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,教育解决方案,应用案例`, ['解决方案', '教育解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(async () => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 3)[0] || {}
  })

  const neirong: any = await asynchronousRetrieval('webimg/images/solution/neirong.png');
  const jishu: any = await asynchronousRetrieval('webimg/images/solution/jishu.png');
  const fuwu: any = await asynchronousRetrieval('webimg/images/solution/fuwu.png');
  const yycj_img5: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img5.png');
  const yycj_icon5: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon5.png');
  const yycj_icon6: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon6.png');
  const yycj_img6: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img6.png');
  const yycj_img7: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img7.png');
  const yycj_icon7: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon7.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  loadUrl.value = { neirong, jishu, fuwu, yycj_img5, yycj_icon5, yycj_icon6, yycj_img6, yycj_img7, yycj_icon7, fays_bg }
})
</script>
<style scoped lang="less">
.education {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 661rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .disadvantage {
    padding: 20rem 40rem;
    width: 1204rem;
    display: inline-block;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 8rem;
    margin: 60rem 0 40rem;
    color: #333;
  }

  .hytd-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80rem;

    .hytd {
      width: 1204rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;
          box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0 0 8rem 8rem;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }

      .images {
        width: 393rem;
        height: 330rem;
        background: #F2F4FA;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem 8rem 0 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;

        img {
          width: 229rem;
          height: 250rem;
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100vw;
    height: 530rem;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;
    text-align: left;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &>div {
      width: 1200rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 393rem;
        height: 410rem;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin: 0 5rem;
        padding: 0rem 0 40rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 30rem;
        text-align: center;
        overflow: hidden;

        &>div {
          padding: 0 22rem;
        }

        .title {
          font-weight: 600;
          font-size: 22rem;
          color: #333333;
          line-height: 30rem;
          font-family: PingFangSC-Medium;
          margin: 42rem 0 10rem;
        }

        img {
          width: 100%;
          height: 230rem;
        }
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      margin-top: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>div {
        width: 1201rem;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;
        margin-bottom: 10rem;

        &>img {
          width: 974rem;
          height: 280rem;
        }

        &>div {
          display: inline-block;
          width: 226rem;
          height: 280rem;
          text-align: left;
          padding: 56rem 20rem;
          box-sizing: border-box;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30rem;
            margin-bottom: 10rem;

            img {
              width: 50rem;
              height: 50rem;
              vertical-align: middle;
              margin-right: 1rem;
              position: relative;
              top: 4rem;
            }

            span {
              vertical-align: middle;
            }
          }

          .content {
            padding: 8rem;
            color: #333333;
            font-size: 16rem;
            line-height: 30rem;
            box-sizing: border-box;
            text-align: justify;
          }
        }
      }

      .jiaoyu {
        &>div {
          padding: 24rem 16rem 24rem 26rem;
        }
      }

      &>div:last-child {
        margin-bottom: 0;
      }

      &>div>div:last-child {
        padding-left: 12rem;
        padding-right: 8rem;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>